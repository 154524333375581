<template>
  <div>
    <div id="container"></div>
  </div>
</template>

<script>
import { TMap } from "../utils/map";
export default {
  data() {
    return {
      longitude: 113.397835,
      latitude: 23.095392,
    };
  },
mounted(){
    setTimeout(()=>{
        this.getMap()
    },500)
  },
  methods: {
    getMap() {
      TMap("H2JBZ-ZMIL2-FU5UZ-CEAZG-5X4TQ-K4B7O").then((qq) => {
        let address = "";
        let that = this;
        console.log(that.latitude, that.longitude);
        var center = new qq.maps.LatLng(that.latitude, that.longitude);

        var map = new qq.maps.Map(document.getElementById('container'), {
          center: center,
          zoom: 15,
          disableDefaultUI: true,
        });
        var marker = new qq.maps.Marker({
          position: center,
          map: map,
        });
        var infoWin = new qq.maps.InfoWindow({
          map: map,
        });

        var geocoder = new qq.maps.Geocoder({
          complete: function (res) {
            // console.log(res);
            address = res.detail.nearPois[0].name;
          },
        });
        geocoder.getAddress(center);
        setTimeout(() => {
          infoWin.open();
          infoWin.setContent(
            '<div style="text-align:center;white-space:nowrap;">' +
              address +
              "</div>"
          );
          infoWin.setPosition(center);
        }, 500);
      
      });
    },
  },
};
</script>

<style scoped>
#container {
  width: 100%;
  height: 280px;
}
@media (max-width: 750px) {
  #container {
  width: 100%;
  height: 155px;
}
}
</style>